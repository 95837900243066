export const FIN_WHITE_LIST_CSV_VALID_FORMAT =
  "Falscher Datentyp. Bitte lade nur CSV-Dateien hoch.";

export const FIN_WHITE_LIST_CSV_POPUP_TEXT =
  "Bitte lade sie FIN-Whitelist hoch. Es werden nur CSV-Datein akzeptiert.";

export const ALLOWED_EXTENSIONS = ["csv"];

export const FILE_SIZE_VALIDATIONS = "Die Datei ist größer als 30 MB."

export const FILE_TYPE_VALIDATIONS = "Die Datei erfüllt nicht das gewünschte Format.";

export const CSV_FILE_UPLOAD_SUCCESS_FROM_SOCKET = "FIN-Whitelist";

export const CSV_FILE_UPLOAD_ERROR_FROM_SOCKET = "Der Upload konnte nicht durchgeführt werden."

export const FIN_WHITE_LIST_SUCCESS_STATUS = "SUCCESS";

export const FIN_WHITE_LIST_FAILED_STATUS = "FAILED";

export const FIN_WHITE_LIST_IN_PROGRESS_STATUS = "IN_PROGRESS";

// Redux Constants Starts

export const UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS =
  "UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS";

export const FIN_WHITE_LIST_STATUS_SUCCESS = "FIN_WHITE_LIST_STATUS_SUCCESS";

export const GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS =
  "GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS";

export const GET_FIN_WHITE_LIST_TENTANTS_SUCCESS =
  "GET_FIN_WHITE_LIST_TENTANTS_SUCCESS";

// Redux Constants Ends
