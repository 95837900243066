import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

// Components
import MDCLabel from "../core/MDCLabel";
import MDCButton from "../core/MDCButton";
import MDCStepper from "../core/MDCStepper";
import MDCLoader from "../core/MDCLoader";
import DocumentUpload from "../shared/DocumentUpload";
import ErrorText from "../shared/ErrorText";

// Button Icons
import RefreshIcon from "../iconComponents/RefreshIcon";

// Constants
import {
  PREFILLED_DOCUMENTS_TITLE,
  VollmachtFormConstants,
  documentNames,
} from "../../constants/ManualRequestFormConstants";

// Material UI
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

// Assets
import verimiImage from "../../assets/images/verimi-image.PNG";

// Actions
import { VollmachtsDatenBankDataActions } from "../../store/actions/userProfile/vollmachtsDatenBankAction";

// Helpers
import { getFileNameFromHttpResponse } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  containerCSS: {
    padding: "1rem",
    backgroundColor: theme.palette.MDCColors.white,
  },
  vollmachtStep: {
    display: "flex",
    flexDirection: "column !important",
    marginBottom: "3rem !important",
  },
  width75: {
    width: "75%",
  },
  QRLoader: {
    marginTop: "1.5rem",
    height: "87px",
    width: "18em",
    display: "flex",
    justifyContent: "center",
  },
  verimiImage: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundImage: `url(${verimiImage})`,
    height: "300px",
    backgroundSize: "90%",
    backgroundPosition: "center",
  },
  vollmachtStep3rd: {
    textAlign: "end",
    alignItems: "flex-end",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
  fieldGroup: {
    marginBottom: "1.25rem !important",
  },
  vollmachtRadioSubHeading: {
    fontSize: "0.875rem",
    marginLeft: "2rem",
    marginTop: "-0.5rem",
  },
  vollmachtSubHeading: {
    fontSize: "0.875rem",
    marginLeft: "2rem",
  },
  prefilledDocumentSection: {
    marginTop: "1rem",
  },
  prefilledDocumentButtonWidth: {
    width: "100%",
  },
  qesPreviewSection: {
    marginTop: "-1.5rem",
  },
}));

const POARecordQES = (props) => {
  const poaId = props?.location?.state?.poaId;

  const {
    containerCSS,
    vollmachtStep,
    width75,
    QRLoader,
    verimiImage,
    vollmachtStep3rd,
    columnTitle,
    fieldGroup,
    vollmachtRadioSubHeading,
    vollmachtSubHeading,
    prefilledDocumentSection,
    prefilledDocumentButtonWidth,
    qesPreviewSection,
  } = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const vollmachtsdatenbankPOAForQESStatus = useSelector(
    (state) => state?.vollmachtDatenBankReducer?.qesStatus
  );

  const vollmachtsdatenbankPOADetails = useSelector(
    (state) => state?.vollmachtDatenBankReducer?.vollmachtsdatenbankPOADetails
  );

  const PPOA_QES_TYPE = "Qes";
  const PPOA_FILE_UPLOAD = "FileUpload";

  const [blinkingValue, setBlinkingValue] = useState(null);
  const [loadingQRCode, setloadingQRCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [qesType, setQesType] = useState(
    vollmachtsdatenbankPOADetails?.signingProcess || ""
  );

  const [isVollmachtDocumentError, setIsVollmachtDocumentError] = useState("");

  const [documentsUpload, setDocumentsUpload] = useState({
    dauervollmacht: vollmachtsdatenbankPOADetails?.dauervollmacht || "",
    sepaMandat: vollmachtsdatenbankPOADetails?.sepaMandat || "",
    signingProcess: qesType || "",
  });

  const [showPrefilledDocumentsLoader, setShowPrefilledDocumentsLoader] =
    useState(false);

  const prevPropsRef = useRef(null);

  let signingProcessTimerRef = useRef(null); // Ref to store the signing Process timer

  const getQRCodeURL = (vollmachtsdatenbankPOAQRCode) => {
    if (vollmachtsdatenbankPOAQRCode?.size > 0) {
      return URL.createObjectURL(vollmachtsdatenbankPOAQRCode);
    }
  };

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (poaId) {
      dispatch(
        VollmachtsDatenBankDataActions.getVollmachtBankPOADetails(poaId)
      ).then((resp) => {
        if (mounted) {
          setQesType(resp?.data?.signingProcess);
          setDocumentsUpload({
            dauervollmacht: resp?.data?.dauervollmacht,
            sepaMandat: resp?.data?.sepaMandat,
            signingProcess: resp?.data?.signingProcess,
          });
          setLoading(false);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [poaId]);

  const vollmachtsdatenbankPOAQRCode = useSelector(
    (state) => state?.vollmachtDatenBankReducer?.QRCode
  );

  const vollmachtsdatenbankPOAForQESUpdate = useSelector(
    (state) =>
      state?.vollmachtDatenBankReducer?.vollmachtsdatenbankPOAForQESUpdate
  );

  const updateErrorMessage =
    vollmachtsdatenbankPOAForQESUpdate?.validationMessages ||
    vollmachtsdatenbankPOADetails?.validationMessages;

  const setBlinkingIconOrRedirectToVollmachtBankList = (completedStage) => {
    if (completedStage === "" || completedStage === "started") {
      setBlinkingValue(1);
    } else if (completedStage === "ident" || completedStage === "verify") {
      setBlinkingValue(2);
    } else if (completedStage === "sign") {
      clearInterval(signingProcessTimerRef?.current);
      setBlinkingValue(3);
      history.push("/vollmachtsdatenbank");
    }
  };

  useEffect(() => {
    const isNonQESProcess = qesType === PPOA_QES_TYPE;

    if (
      isNonQESProcess &&
      (vollmachtsdatenbankPOAQRCode === undefined ||
        vollmachtsdatenbankPOAQRCode === null)
    ) {
      setloadingQRCode(true);
      dispatch(
        VollmachtsDatenBankDataActions.getVollmachtBankPOAQRCode(poaId)
      ).then(() => setloadingQRCode(false));
    }

    prevPropsRef.current =
      vollmachtsdatenbankPOAForQESStatus !== undefined &&
      vollmachtsdatenbankPOAForQESStatus.completedStage;

    if (
      prevPropsRef?.current === undefined ||
      ((prevPropsRef?.current?.completedStage ?? null) !==
        vollmachtsdatenbankPOAForQESStatus?.completedStage &&
        blinkingValue !== 3)
    ) {
      const completedStage = vollmachtsdatenbankPOAForQESStatus?.completedStage;

      if (
        prevPropsRef?.current?.completedStage !==
        vollmachtsdatenbankPOAForQESStatus?.completedStage
      ) {
        setBlinkingIconOrRedirectToVollmachtBankList(completedStage);
      } else if (
        prevPropsRef?.current?.completedStage ===
        vollmachtsdatenbankPOAForQESStatus?.completedStage
      ) {
        setBlinkingIconOrRedirectToVollmachtBankList(completedStage);
      }

      if (isNonQESProcess) {
        signingProcessTimerRef.current = setInterval(() => {
          dispatch(
            VollmachtsDatenBankDataActions.getVollmachtBankSigningStatus(poaId)
          );
        }, 10000);

        return () => {
          clearInterval(signingProcessTimerRef?.current);
        };
      }
    }

    return () => {
      if (qesType === PPOA_FILE_UPLOAD && signingProcessTimerRef?.current) {
        clearInterval(signingProcessTimerRef?.current);
      }
    };
  }, [vollmachtsdatenbankPOAForQESStatus, qesType, dispatch]);

  const checkForFileUploadError = () => {
    //If Any/All of the 2 documents contains the error then it will be displayed in Error text component under the document upload section
    let fileError = "";
    let dataArray = Object.keys(documentNames);

    for (let i = 0; i < dataArray.length; i++) {
      if (documentsUpload[dataArray[i]]?.error) {
        fileError = documentsUpload[dataArray[i]]?.error;
        break;
      }
    }
    return fileError;
  };
  const handleDocumentUpload = (event) => {
    const documentValue = { ...documentsUpload };
    documentValue[event.target.name] = event.target.value;
    setDocumentsUpload(documentValue);
  };

  const handlePOAQES = () => {
    const documentValues = {
      ...documentsUpload,
      signingProcess: PPOA_QES_TYPE,
    };

    dispatch(
      VollmachtsDatenBankDataActions.updateVollmachtBankPOAForQES(
        documentValues,
        poaId
      )
    ).then((resp) => {
      if (resp?.data) {
        dispatch(
          VollmachtsDatenBankDataActions.getVollmachtBankPOADetails(poaId)
        );
      }
    });
  };

  const handleQESTypeChange = (event) => {
    const value = event.target.value;

    if (
      value === PPOA_QES_TYPE &&
      (documentsUpload?.sepaMandat !== "" ||
        documentsUpload?.dauervollmacht !== "")
    ) {
      setIsVollmachtDocumentError(
        "Bitte löschen Sie zunächst die hochgeladenen Dokumente"
      );
    } else {
      setIsVollmachtDocumentError("");

      setQesType(value);
      if (value === PPOA_QES_TYPE) {
        handlePOAQES();
      }
    }
  };

  const downloadPrefilledDocuments = () => {
    setShowPrefilledDocumentsLoader(true);
    dispatch(
      VollmachtsDatenBankDataActions.getPrefilledPPOADocumentsDownload(poaId)
    ).then((resp) => {
      setShowPrefilledDocumentsLoader(false);
      const fileName = getFileNameFromHttpResponse(resp);
      const url = window.URL.createObjectURL(new Blob([resp?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const QESProcessView = (
    <div className={qesPreviewSection}>
      {/* Blinker stepper */}
      <Grid marginBottom={"1rem"} width={"100%"}>
        <MDCStepper step={blinkingValue} />
      </Grid>

      <Grid container>
        <Grid item xs={4} className={vollmachtStep} id="step-1-container">
          <>
            <Grid marginBottom="2.5rem">
              <MDCLabel label="Scan" variant="h6" />
              <p className={width75}>
                {VollmachtFormConstants.STEP_ONE_CONTENT}
              </p>
            </Grid>
            <div>
              <MDCButton
                variant="outlined"
                label={"Neuen QR Code generieren"}
                disabled={false}
                onClick={() => {
                  setloadingQRCode(true);
                  dispatch(
                    VollmachtsDatenBankDataActions.regenerateVollmachtBankPOAQRCode(
                      poaId
                    )
                  ).then(() => {
                    setloadingQRCode(false);
                  });
                }}
                endIcon={<RefreshIcon />}
              />
            </div>
            <div>
              {loadingQRCode ? (
                <div className={QRLoader}>
                  <MDCLoader />
                </div>
              ) : (
                <>
                  {!loadingQRCode &&
                    vollmachtsdatenbankPOAQRCode !== undefined &&
                    vollmachtsdatenbankPOAQRCode?.size > 0 && (
                      <img
                        alt="Vollmacht QR Code"
                        src={getQRCodeURL(vollmachtsdatenbankPOAQRCode)}
                        width="50%"
                      />
                    )}
                </>
              )}
            </div>
          </>
        </Grid>

        <Grid item xs={4} className={vollmachtStep} id="step-2-container">
          <>
            <Grid textAlign="center" marginBottom="1.5rem">
              <MDCLabel label="Vollmachterstellung via Verimi" variant="h6" />
              <p>{VollmachtFormConstants.STEP_TWO_CONTENT}</p>
            </Grid>
            <div className={verimiImage} />
          </>
        </Grid>

        <Grid
          item
          xs={4}
          className={`${vollmachtStep} ${vollmachtStep3rd}`}
          id="step-3-container"
        >
          <Grid width="18rem" marginBottom="2.5rem">
            <MDCLabel label="Vollmacht empfangen" variant="h6" />
            <p>{VollmachtFormConstants.STEP_THREE_CONTENT}</p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  const documentUploadView = (
    <Grid marginY={".5rem"}>
      <label className={columnTitle}>Vollmachtsdokumenten-Upload</label>
      <p>
        {
          "Hier können Sie Ihre digital signierten (z.B. durch Verimi) Vollmachtsdokumente als PDF hochladen."
        }
      </p>
      {Object.keys(documentNames).map((docName, index) => {
        return index === 1 &&
          (vollmachtsdatenbankPOADetails?.zollGkVorhanden ||
            vollmachtsdatenbankPOADetails?.steuerbefreiung === "") ? null : (
          <DocumentUpload
            key={index}
            documentName={docName}
            uploadedFileData={documentsUpload[docName]}
            handleChange={(e) => handleDocumentUpload(e)}
            error={updateErrorMessage && updateErrorMessage[docName]}
            isShowUploadedLabel
          />
        );
      })}
      <ErrorText id="file-upload-error" error={checkForFileUploadError()} />
    </Grid>
  );

  return (
    <Grid container className={containerCSS}>
      {/* Body */}
      {loading ? (
        <Grid container margin={"10rem"} justifyContent={"center"}>
          <MDCLoader />
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4} className={fieldGroup}>
              <Grid marginY={".5rem"}>
                <label className={columnTitle}>
                  Bitte wählen Sie eine Option für die Vollmacht:
                </label>

                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="signingProcess"
                    name="signingProcess"
                    value={qesType}
                    onChange={(e) => handleQESTypeChange(e)}
                  >
                    <FormControlLabel
                      value={PPOA_QES_TYPE}
                      control={<Radio />}
                      // className={defaultRadioButtonStyles}
                      label="Digitale Vollmacht und SEPA-Mandat neu erstellen"
                      disabled={props?.readOnly}
                    />

                    <p className={vollmachtRadioSubHeading}>
                      Unterstützt werden bei dieser Variante GmbH, UG, AG, SE,
                      e.V..
                    </p>

                    <FormControlLabel
                      value={PPOA_FILE_UPLOAD}
                      control={<Radio />}
                      className={"defaultRadioButtonStyles"}
                      label="Digital signierte Vollmacht und SEPA-Mandat (KBA-Vorlage) hochladen"
                      disabled={props?.readOnly}
                    />

                    <p className={vollmachtSubHeading}>
                      Die digitale Signatur sollte durch ein IdM wie Verimi
                      erstellt worden sein.
                    </p>
                  </RadioGroup>

                  {qesType === PPOA_FILE_UPLOAD && (
                    <div className={prefilledDocumentSection}>
                      <label className={columnTitle}>
                        {PREFILLED_DOCUMENTS_TITLE}
                      </label>

                      <MDCButton
                        className={prefilledDocumentButtonWidth}
                        variant="outlined"
                        label="Dokumente für Signatur herunterladen"
                        disabled={showPrefilledDocumentsLoader}
                        onClick={() => downloadPrefilledDocuments()}
                      />
                    </div>
                  )}

                  <ErrorText error={isVollmachtDocumentError} />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={qesType === PPOA_QES_TYPE ? 8 : 4}
              className={fieldGroup}
            >
              {qesType === PPOA_QES_TYPE ? QESProcessView : documentUploadView}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container mt="2.5rem" justifyContent="space-between">
        <Grid item>
          <MDCButton
            onClick={() => history.push("/vollmachtsdatenbank")}
            variant="outlined"
            label="Schließen"
          />
        </Grid>
        <Grid item>
          {qesType !== PPOA_QES_TYPE && (
            <MDCButton
              onClick={() => {
                setLoading(true);
                dispatch(
                  VollmachtsDatenBankDataActions.updateVollmachtBankPOAForQES(
                    { ...documentsUpload, signingProcess: qesType },
                    poaId
                  )
                ).then((resp) => {
                  if (resp?.data) {
                    dispatch(
                      VollmachtsDatenBankDataActions.getVollmachtBankPOADetails(
                        poaId
                      )
                    );
                    setLoading(false);
                  }
                });
              }}
              variant="contained"
              label={"In Datenbank speichern"}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default POARecordQES;
