import { toast } from "react-toastify";
import NotificationMessage from "../../../components/core/NotificationMessage";
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";
import { userConstants } from "../../../constants/userConstants";
import { VERIMI_ADMIN_CONSTANTS } from "../../../constants/verimiAdminConstants";
import { verimiAdminService } from "../../services/admin/verimiAdminService";

function getLogoSuccess(logo) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_LOGO_SUCCESS, logo };
}

function getLogo(alaisName) {
  return (dispatch) => {
    return verimiAdminService.getDetail(alaisName, "logo").then((logo) => {
      dispatch(getLogoSuccess(logo));
    });
  };
}

function getDetailsSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_DETAIL_SUCCESS, details };
}

function getDetail(aliasName) {
  return (dispatch) => {
    return verimiAdminService
      .getDetail(aliasName, "details")
      .then((details) => {
        dispatch(getDetailsSuccess(details));
        return details;
      });
  };
}

function userUpdateSucess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.UPDATE_DETAIL_SUCCESS, details };
}

function updateDetail(aliasName, requestData) {
  return (dispatch) => {
    return verimiAdminService
      .updateDetails(aliasName, requestData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(userUpdateSucess(response.data.responseData));
          const user = JSON.parse(localStorage.getItem("user"));
          dispatch({ type: userConstants.USER_UPDATE, user });
        } else if (response?.response?.status === 400) {
          return response.response.data;
        }
      });
  };
}
function getOperationIDsSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_OPERATION_IDS_SUCCESS, details };
}

function getOperationIDs(query, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .getOperationIDs(query, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(getOperationIDsSuccess(response.data));
          return response;
        }
      });
  };
}

function getUserDetailsDataSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_USER_DETAILS_SUCCESS, details };
}
function getWebhookDetailsDataSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_WEBHOOK_DETAILS_SUCCESS, details };
}
function getUserDetailsData(aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .getUserDetailsData(aliasName, tenantGroupData)
      .then((response) => {
        response?.usersList &&
          dispatch(getUserDetailsDataSuccess(response.usersList));
      });
  };
}

function getWebhookDetailsData(aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .getWebhookDetailsData(aliasName, tenantGroupData)
      .then((response) => {
        if (HTTP_SUCCESS_STATUS_CODES.includes(response?.status)) {
          dispatch(getWebhookDetailsDataSuccess(response?.data));
          return response?.data;
        }
      });
  };
}

function deleteWebhookDetails(aliasName, tenantGroupData) {
  return () => {
    return verimiAdminService
      .deleteWebhookDetails(aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status)
        ) {
          return response;
        }
      });
  };
}

function testWebhookRequestSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.TEST_WEBHOOK_DETAILS_SUCCESS, details };
}
function testWebhookRequest(webhookDetails) {
  return (dispatch) => {
    return verimiAdminService
      .testWebhookRequest(webhookDetails)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
          response.data
        ) {
          dispatch(testWebhookRequestSuccess(response?.data?.responseData));
          return response?.data?.responseData;
        }
      });
  };
}

function submitWebhookRequestSuccess(details) {
  return {
    type: VERIMI_ADMIN_CONSTANTS.SUBMIT_WEBHOOK_DETAILS_SUCCESS,
    details,
  };
}
function submitWebhookRequest(aliasName, tenantGroupData, webhookDetails) {
  return (dispatch) => {
    return verimiAdminService
      .submitWebhookRequest(aliasName, tenantGroupData, webhookDetails)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status)
        ) {
          if (!response?.data?.responseData?.errorMessage) {
            toast.success(
              <NotificationMessage message={response?.data?.message} />
            );
          }
          dispatch(submitWebhookRequestSuccess(response?.data?.responseData));
          return response?.data?.responseData;
        }
      });
  };
}
function updateWebhookRequest(aliasName, tenantGroupData, webhookDetails) {
  return () => {
    return verimiAdminService
      .updateWebhookRequest(aliasName, tenantGroupData, webhookDetails)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status)
        ) {
          if (!response?.data?.responseData?.errorMessage) {
            toast.success(
              <NotificationMessage message={response?.data?.message} />
            );
          }
          return response?.data?.responseData;
        }
      });
  };
}

function getNachrichtenDataSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_NACHRICHTEN_DATA_SUCCESS, details };
}
function getNachrichtenData(query) {
  return (dispatch) => {
    return verimiAdminService.getNachrichtenData(query).then((response) => {
      if (
        !response?.isAxiosError &&
        HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
        response.data
      ) {
        dispatch(getNachrichtenDataSuccess(response.data));
        return response;
      }
    });
  };
}

function handleNachrichtenActionPopupSubmit(actionType, id) {
  return (dispatch) => {
    return verimiAdminService
      .handleNachrichtenActionPopupSubmit(actionType, id)
      .then((details) => {
        dispatch(getDetailsSuccess(details));
        return details;
      });
  };
}

function getUserGroupsDataSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_USER_GROUPS_SUCCESS, details };
}

function getUserGroupsData() {
  return (dispatch) => {
    return verimiAdminService.getUserGroupsData().then((response) => {
      if (
        !response?.isAxiosError &&
        HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
        response.data
      ) {
        dispatch(getUserGroupsDataSuccess(response.data));
        return response;
      }
    });
  };
}

function getOperationIDsLinkedWithEmployeeIDSuccess(details) {
  return {
    type: VERIMI_ADMIN_CONSTANTS.GET_OPERATION_IDS_ASSOCIATED_TO_EMPLOYEE_ID_SUCCESS,
    details,
  };
}

function getOperationIDsLinkedWithEmployeeID(
  employeeID,
  aliasName,
  tenantGroupData
) {
  return (dispatch) => {
    return verimiAdminService
      .getOperationIDsLinkedWithEmployeeID(
        employeeID,
        aliasName,
        tenantGroupData
      )
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(getOperationIDsLinkedWithEmployeeIDSuccess(response.data));

          return response;
        }
      });
  };
}

function addOperationID(data, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .addOperationID(data, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(getOperationIDs({}, aliasName, tenantGroupData));
          toast.success(
            <NotificationMessage message={response.data.message} />
          );
          return response;
        } else {
          return response.response;
        }
      });
  };
}

function getEmpoyeeIDsLinkedWithOperationIDSuccess(details) {
  return {
    type: VERIMI_ADMIN_CONSTANTS.GET_EMPLOYEE_IDS_ASSOCIATED_TO_OPERATION_ID_SUCCESS,
    details,
  };
}

function getEmployeeIDsLinkedWithOperationID(
  operationID,
  aliasName,
  tenantGroupData
) {
  return (dispatch) => {
    return verimiAdminService
      .getEmployeeIDsLinkedWithOperationID(
        operationID,
        aliasName,
        tenantGroupData
      )
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(getEmpoyeeIDsLinkedWithOperationIDSuccess(response.data));
        }
      });
  };
}

function addLinkedIDs(operationId, employeeId, isOperationIdSelected) {
  return (dispatch) => {
    return verimiAdminService
      .addLinkedIDs(operationId, employeeId)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          isOperationIdSelected
            ? dispatch(
                getEmployeeIDsLinkedWithOperationID(operationId, aliasName)
              )
            : dispatch(
                getOperationIDsLinkedWithEmployeeID(employeeId, aliasName)
              );
        }
      });
  };
}
function updateOperationID(id, formData, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .updateOperationID(id, formData, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          toast.success(
            <NotificationMessage message={response.data.message} />
          );
          dispatch(getOperationIDs({}, aliasName, tenantGroupData));
          return response;
        } else {
          return response.response;
        }
      });
  };
}
function deleteOperationID(id, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .deleteOperationID(id, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          dispatch(getOperationIDs({}, aliasName, tenantGroupData));
          toast.success(
            <NotificationMessage
              message={
                response.data.message ||
                "Der Betrieb wurde erfolgreich gelöscht."
              }
            />
          );
          return response;
        }
      });
  };
}

function deleteLinkedIDs(operationId, employeeId, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .deleteLinkedIDs(operationId, employeeId, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          dispatch(
            getEmployeeIDsLinkedWithOperationID(
              operationId,
              aliasName,
              tenantGroupData
            )
          );
          dispatch(
            getOperationIDsLinkedWithEmployeeID(
              employeeId,
              aliasName,
              tenantGroupData
            )
          );
        }
      });
  };
}

function getEmpoyeeIDsSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.GET_EMPLOYEE_IDS_SUCCESS, details };
}

function getEmployeeIDs(query, aliasName, tenantGroupData) {
  return (dispatch) => {
    return verimiAdminService
      .getEmployeeIDs(query, aliasName, tenantGroupData)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(getEmpoyeeIDsSuccess(response.data));
          return response;
        }
      });
  };
}

function linkOperationIdsWithEmployeeId(
  employeeId,
  operationIds,
  aliasName,
  tenantGroupData
) {
  return (dispatch) => {
    return verimiAdminService
      .linkOperationIdsWithEmployeeId(
        employeeId,
        operationIds,
        aliasName,
        tenantGroupData
      )
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          dispatch(
            getOperationIDsLinkedWithEmployeeID(
              employeeId,
              aliasName,
              tenantGroupData
            )
          );
          return response;
        }
      });
  };
}
function deleteTenantGKSCertificate(tenantAliasName, removeGKSDetails) {
  return (dispatch) => {
    return verimiAdminService
      .deleteTenantGKSCertificate(tenantAliasName, removeGKSDetails)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          dispatch(getDetail(tenantAliasName)); //For updating the details of the tenant after deleting GKS certificate
          return response;
        }
      });
  };
}

function addOperationIdLinkedWithEmployeeIds(
  operationId,
  formData,
  aliasName,
  tenantGroupData
) {
  return (dispatch) => {
    return verimiAdminService
      .addOperationIdLinkedWithEmployeeIds(
        operationId,
        formData,
        aliasName,
        tenantGroupData
      )
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)
        ) {
          dispatch(
            getEmployeeIDsLinkedWithOperationID(
              operationId,
              aliasName,
              tenantGroupData
            )
          );
        }
      });
  };
}

function handleAdminActionPopupSubmit(actionType, id) {
  return async () => {
    return verimiAdminService
      .handleAdminActionPopupSubmit(actionType, id)
      .then((details) => {
        return details;
      });
  };
}

function updateUserDetails(updatedUserDetails, id) {
  return () => {
    return verimiAdminService
      .updateUserDetails(updatedUserDetails, id)
      .then((response) => {
        return response;
      });
  };
}
function createUser(userDetails, tenantGroupData, aliasName) {
  return () => {
    return verimiAdminService
      .createUser(userDetails, tenantGroupData, aliasName)
      .then((response) => {
        return response;
      });
  };
}

function createNachrichtenSuccess(details) {
  return { type: VERIMI_ADMIN_CONSTANTS.CREATE_NACHRICHTEN_SUCCESS, details };
}
function createNachrichten(data, updateMessageFlag) {
  return (dispatch) => {
    return verimiAdminService
      .createNachrichten(data, updateMessageFlag)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
          response.data
        ) {
          dispatch(createNachrichtenSuccess(response.data));
          toast.success(
            <NotificationMessage message={response.data.message} />
          );
          return response;
        } else {
          return response?.response;
        }
      });
  };
}
function automateEinreichenRequests(aliasName, autoForward) {
  return async () => {
    const response = await verimiAdminService.automateEinreichenRequests(
      aliasName,
      autoForward
    );
    return response;
  };
}
function getAutoForwardUserDetailsSuccess(details) {
  return {
    type: VERIMI_ADMIN_CONSTANTS.AUTOFORWARD_USERDETAILS_SUCCESS,
    details,
  };
}
function getAutoForwardUserDetails(userEmailID) {
  return async (dispatch) => {
    const response = await verimiAdminService.getAutoForwardUserDetails(
      userEmailID
    );
    if (
      HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
      response?.data
    ) {
      dispatch(getAutoForwardUserDetailsSuccess(response.data));
    }
    return response;
  };
}

function getRegistrationTypesListSuccess(details) {
  return {
    type: VERIMI_ADMIN_CONSTANTS.REGISTRATION_TYPES_LIST,
    details,
  };
}
function getRegistrationTypesList(aliasName) {
  return async (dispatch) => {
    const response = await verimiAdminService.getRegistrationTypesList(
      aliasName
    );
    if (
      HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
      response?.data
    ) {
      dispatch(getRegistrationTypesListSuccess(response.data));
    }
    return response;
  };
}

function updateRegistrationTypesList(aliasName, registrationTypes) {
  return async (dispatch) => {
    const response = await verimiAdminService.updateRegistrationTypesList(
      aliasName,
      registrationTypes
    );
    if (HTTP_SUCCESS_STATUS_CODES.includes(response?.status)) {
      dispatch(getRegistrationTypesListSuccess(registrationTypes));
    }

    return response;
  };
}

function getAPIUserIdDetails() {
  return async () => {
    const response = await verimiAdminService.getAPIUserIdDetails();
    if (
      HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
      response?.data
    ) {
      return response;
    }
  };
}

function downloadGKSCertificate(tenantAliasName, fileType) {
  return async () => {
    const response = await verimiAdminService.downloadGKSCertificate(
      tenantAliasName,
      fileType
    );
    if (
      HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
      response?.data
    ) {
      return response;
    }
  };
}

function renewKbaPassword(kbaPasswordDetails, groupId, tenantAlias) {
  return () => {
    return verimiAdminService
      .renewKbaPassword(kbaPasswordDetails, groupId, tenantAlias)
      .then((response) => {
        return response;
      });
  };
}

export const verimiAdminActions = {
  // Function added for unit test case start
  getWebhookDetailsDataSuccess,
  testWebhookRequestSuccess,
  submitWebhookRequestSuccess,
  getLogoSuccess,
  getDetailsSuccess,
  userUpdateSucess,
  getOperationIDsSuccess,
  getEmpoyeeIDsSuccess,
  getEmpoyeeIDsLinkedWithOperationIDSuccess,
  getOperationIDsLinkedWithEmployeeIDSuccess,
  getUserDetailsDataSuccess,
  getNachrichtenDataSuccess,
  // Function added for unit test case ends
  getLogo,
  getDetail,
  updateDetail,
  getOperationIDs,
  addOperationID,
  updateOperationID,
  deleteOperationID,
  deleteLinkedIDs,
  getEmployeeIDs,
  linkOperationIdsWithEmployeeId,
  getEmployeeIDsLinkedWithOperationID,
  getOperationIDsLinkedWithEmployeeID,
  addLinkedIDs,
  deleteTenantGKSCertificate,
  addOperationIdLinkedWithEmployeeIds,
  getUserDetailsData,
  getWebhookDetailsData,
  deleteWebhookDetails,
  testWebhookRequest,
  submitWebhookRequest,
  updateWebhookRequest,
  getUserGroupsData,
  handleAdminActionPopupSubmit,
  updateUserDetails,
  createUser,
  getNachrichtenData,
  createNachrichten,
  handleNachrichtenActionPopupSubmit,
  automateEinreichenRequests,
  getAutoForwardUserDetails,
  getRegistrationTypesList,
  updateRegistrationTypesList,
  getAPIUserIdDetails,
  downloadGKSCertificate,
  renewKbaPassword,
};
