import React from "react";
import { connect } from "react-redux";
import carLogo from "../../../assets/images/my_digital_car.svg";
import MDCButton from "../../../components/core/MDCButton";
import MDCModal from "../../../components/core/MDCModal";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../../themes/mdc-theme";

const styles = () => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  contentCss: {
    fontFamily: theme.palette.typography.fontFamily,
    color: theme.palette.MDCColors.color15,
    padding: "0 2rem",
    wordBreak: "break-all",
  },
});
class MessageDetailsPopupDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, messageDetails } = this.props;
    const getModalHeader = () => {
      return (
        <Grid container className={classes.headerCss}>
          <Grid item xs={7} className={classes.headerColor}>
            <h2> Nachrichtendetails</h2>
          </Grid>
          <Grid item xs={5}>
            <img alt="My Digital Car" src={carLogo} width="95%" />
          </Grid>
        </Grid>
      );
    };
    const getModalBody = () => {
      return (
        <Grid container justifyContent="center" className={classes.contentCss}>
          {messageDetails}
        </Grid>
      );
    };
    const getModalFooter = () => {
      return (
        <Grid
          container
          pb={3}
          paddingX={"2rem"}
          justifyContent="flex-end"
          className={classes.footerCss}
        >
          <Grid item>
            <MDCButton
              onClick={() => this.props.handlePopupClose()}
              variant="contained"
              label="Schließen"
            />
          </Grid>
        </Grid>
      );
    };
    return (
      <div>
        <MDCModal
          open={this.props.show}
          title={getModalHeader()}
          body={getModalBody()}
          footer={getModalFooter()}
          size="sm"
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MessageDetailsPopupDialog));
