import axios from "axios";

// Config
import { config } from "../config";
import { convertKeycloakObjectToClientObject } from "../../../helpers/adminUtils";
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";

const dateColumns = ["letzteAnderung"];

const getDetail = (aliasName, type) => {
  const responseType = type === "logo" ? "blob" : "json";
  return axios
    .get(`${config.apiUrl}/tcs/${aliasName}/${type}`, { responseType })
    .then((response) => {
      if (response?.status === 200) {
        if (response.headers["content-type"] === "application/octet-stream") {
          return {
            fileName: response.headers["content-disposition"]
              ?.split("=")[1]
              .replaceAll('"', ""),
            file: response.data,
          };
        }
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    });
};

function updateDetails(aliasName, requestData) {
  const url = `${config.apiUrl}/tcs/${aliasName}/details`;
  return axios
    .put(url, requestData)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getOperationIDs(query, aliasName, tenantGroupData) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let activeUser = JSON.parse(localStorage.getItem("activeUser"));

  if (Object.keys(query).length !== 0) {
    let groupId = user?.roles?.includes("MDC_ADMIN")
      ? tenantGroupData?.groupId
      : activeUser?.groupID;

    url = `${
      config.apiUrl
    }/tcs/${groupId}/${aliasName}/operations/pageable?isNotAssigned=${
      query?.isNotAssigned ? query?.isNotAssigned : false
    }&size=${query.pageSize}&page=${query.page}`;

    if (query.orderBy && query.orderDirection !== "") {
      url += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
    }

    //handle filters and adding in query params with url---------
    if (query?.filters && query?.filters.length > 0) {
      let filters = "";

      query?.filters.map((filter) => {
        const filterValue = encodeURIComponent(filter.value);

        if (filters !== "") {
          filters += "&";
        }
        filters += `${filter.column.field}${filter.operator}${filterValue}`;
        return filters;
      });
      url += `&${filters}`;
    }
  } else {
    let groupId = user?.roles?.includes("MDC_ADMIN")
      ? tenantGroupData?.groupId
      : user?.pGroupId;

    url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations`;
  }

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function addOperationID(formData, aliasName, tenantGroupData) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations`;

  return axios
    .post(url, formData)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function addLinkedIDs(operationId, employeeId) {
  //need to verify if we are using it
  const url = `${config.apiUrl}/tcs/operations/${operationId}/employees/${employeeId}`;
  return axios
    .post(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function updateOperationID(id, formData, aliasName, tenantGroupData) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${id}`;

  return axios
    .put(url, formData)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function deleteOperationID(id, aliasName, tenantGroupData) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${id}`;

  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function deleteWebhookDetails(aliasName, tenantGroupData) {
  let url = `${config.apiUrl}/webhooks/${tenantGroupData?.groupId}/${aliasName}`;

  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function deleteLinkedIDs(operationId, employeeId, aliasName, tenantGroupData) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${operationId}/employees/${employeeId}`;

  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getEmployeeIDs(query, aliasName, tenantGroupData) {
  let url;
  const user = JSON.parse(localStorage.getItem("user"));
  if (Object.keys(query).length !== 0) {
    let groupId = user?.roles?.includes("MDC_ADMIN")
      ? tenantGroupData?.groupId
      : user?.pGroupId;

    url = `${
      config.apiUrl
    }/users/${groupId}/${aliasName}/employees?isNotAssigned=${
      query?.isNotAssigned ? true : false
    }&size=${query.pageSize}&page=${query.page}`;

    if (query.orderBy && query.orderDirection !== "") {
      url += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
    }
    //handle filters and adding in query params with url---------
    if (query?.filters && query?.filters.length > 0) {
      let filters = "";
      query?.filters.map((filter) => {
        const filterValue = encodeURIComponent(filter.value);

        if (filters !== "") {
          filters += "&";
        }
        filters += `${filter.column.field}${filter.operator}${filterValue}`;
        return filters;
      });
      url += `&${filters}`;
    }
  } else {
    let groupId = user?.roles?.includes("MDC_ADMIN")
      ? tenantGroupData?.groupId
      : user?.pGroupId;

    url = `${config.apiUrl}/users/${groupId}/${aliasName}/employees`;
  }
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function linkOperationIdsWithEmployeeId(
  employeeId,
  operationIds,
  aliasName,
  tenantGroupData
) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${employeeId}/operationUUIds`;

  return axios
    .post(url, operationIds)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getEmployeeIDsLinkedWithOperationID(
  operationID,
  aliasName,
  tenantGroupData
) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${operationID}/employees`;

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getOperationIDsLinkedWithEmployeeID(
  employeeID,
  aliasName,
  tenantGroupData
) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/by-employee/${employeeID}`;

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function deleteTenantGKSCertificate(tenantAliasName, removeGKSDetails) {
  const url = `${
    config.apiUrl
  }/tcs/certificate/${tenantAliasName}?removeGKSDetails=${
    removeGKSDetails ? removeGKSDetails : false
  }`;
  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function addOperationIdLinkedWithEmployeeIds(
  operationId,
  formData,
  aliasName,
  tenantGroupData
) {
  let url;
  let user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/tcs/${groupId}/${aliasName}/operations/${operationId}/employees`;

  return axios
    .post(url, formData)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
const convertKeyCloakUsersToClientUsers = (usersList) => {
  usersList?.forEach((user) => {
    convertKeycloakObjectToClientObject(user);
  });
  return usersList;
};

function getUserDetailsData(aliasName, tenantGroupData) {
  let url;
  var headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const user = JSON.parse(localStorage.getItem("user"));
  let groupId = user?.roles?.includes("MDC_ADMIN")
    ? tenantGroupData?.groupId
    : user?.pGroupId;

  url = `${config.apiUrl}/groups/users?groupName=Customer/${groupId}/tenant/${aliasName}`;

  return axios
    .get(url, headers)
    .then((resp) => {
      if (
        HTTP_SUCCESS_STATUS_CODES.includes(resp?.status) &&
        resp?.data?.length
      ) {
        resp.usersList = convertKeyCloakUsersToClientUsers(resp?.data);
      }
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getUserGroupsData() {
  let url;
  var headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  url = `${config.apiUrl}/groups/access-roles`;

  return axios
    .get(url, headers)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function getWebhookDetailsData(aliasName, tenantGroupData) {
  let url = `${config.apiUrl}/webhooks/${tenantGroupData?.groupId}/${aliasName}`;

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function testWebhookRequest(webhookDetails) {
  let url = `${config.apiUrl}/webhooks/test`;
  return axios
    .post(url, webhookDetails)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function submitWebhookRequest(aliasName, tenantGroupData, webhookDetails) {
  let url = `${config.apiUrl}/webhooks/${tenantGroupData?.groupId}/${aliasName}`;
  return axios
    .post(url, webhookDetails)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function updateWebhookRequest(aliasName, tenantGroupData, webhookDetails) {
  let url = `${config.apiUrl}/webhooks/${tenantGroupData?.groupId}/${aliasName}`;
  return axios
    .put(url, webhookDetails)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getNachrichtenData(query) {
  var headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let url = `${config.apiUrl}/status/broadcast-message?size=${
    query?.pageSize || 5
  }&page=${query?.page || 0}`;

  if (query?.orderBy && query?.orderDirection !== "") {
    url += `&sort=${query?.orderBy?.field}&order=${query?.orderDirection}`;
  }

  //handle filter
  if (query?.filters && query?.filters?.length > 0) {
    let filters = "";
    query?.filters?.map((filter) => {
      if (filters !== "") {
        filters += "&";
      }
      if (dateColumns.includes(filter.column.field)) {
        filter.value = moment(filter.value).format("YYYY-MM-DD");
      }
      return (filters += `${filter.column.field}${filter.operator}${filter.value}`);
    });
    url += `&${filters}`;
  }

  return axios
    .get(url, headers)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function handleAdminActionPopupSubmit(actionType, id) {
  const url = `${config.apiUrl}/users/${id}`;
  switch (actionType) {
    case "activate":
      return axios
        .patch(url + `?isEnabled=true`)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
    case "deactivate":
      return axios
        .patch(url + `?isEnabled=false`)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
    case "delete":
      return axios
        .delete(url)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
  }
}
function handleNachrichtenActionPopupSubmit(actionType, ids) {
  const url = `${config.apiUrl}/status/broadcast-message`;

  let payload = {
    messageIds: ids,
    status:
      actionType === "activate" ? "Nachricht aktiv" : "Nachricht deaktiviert",
  };

  switch (actionType) {
    case "activate":
      return axios
        .patch(url, payload)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
    case "deactivate":
      return axios
        .patch(url, payload)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
    case "delete":
      payload = {
        data: ids,
      };
      return axios
        .delete(url, payload)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error;
        });
  }
}

function updateUserDetails(updatedUserDetails, id) {
  const url = `${config.apiUrl}/users/${id}`;
  let modifyObj = function (updatedUserDetails) {
    Object.keys(updatedUserDetails)?.forEach(function (key) {
      if (key === "enabled") {
        updatedUserDetails[key] =
          updatedUserDetails[key] === "Aktiv" ? true : false;
      } else if (key === "employeeId") {
        updatedUserDetails[key] = Array.isArray(updatedUserDetails[key])
          ? updatedUserDetails[key][0]
          : updatedUserDetails[key];
      } else if (key === "groups") {
        if (typeof updatedUserDetails["groups"] !== "object") {
          updatedUserDetails[key] = updatedUserDetails["previousGroups"];
        } else if (typeof updatedUserDetails["groups"] === "object") {
          let previousSelectedGroups = [];
          updatedUserDetails["previousGroups"]?.forEach((roleDetails) => {
            if (roleDetails["path"].split("/")?.includes("Customer")) {
              previousSelectedGroups.push(roleDetails);
            }
          });

          updatedUserDetails[key] = updatedUserDetails[key];

          updatedUserDetails[key] = updatedUserDetails[key]?.concat(
            previousSelectedGroups
          );
        }
      } else {
        updatedUserDetails[key] = updatedUserDetails[key];
      }
    });
    return updatedUserDetails;
  };

  return axios
    .put(url, modifyObj(updatedUserDetails))
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function createUser(userDetails, tenantGroupData, aliasName) {
  let modifyCreateObj = function (updatedUserDetails) {
    Object.keys(updatedUserDetails).forEach(function (key) {
      if (key === "employeeId") {
        updatedUserDetails[key] = Array.isArray(updatedUserDetails[key])
          ? updatedUserDetails[key][0]
          : updatedUserDetails[key];
      } else if (key === "groups") {
        updatedUserDetails[key] = updatedUserDetails[key]?.concat({
          name: aliasName,
          path: `/Customer/${tenantGroupData.groupId}/tenant/${aliasName}`,
        });
      } else {
        updatedUserDetails[key] = updatedUserDetails[key];
      }
    });
    return updatedUserDetails;
  };

  const url = `${config.apiUrl}/users`;

  return axios
    .post(url, modifyCreateObj(userDetails))
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function createNachrichten(data, updateMessageFlag) {
  let url = `${config.apiUrl}/status/broadcast-message`;

  if (updateMessageFlag) {
    url = `${config.apiUrl}/status/broadcast-message/${data?.msgId}`;
    return axios
      .put(url, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  } else {
    return axios
      .post(url, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  }
}

function editNachrichten(data, requestId) {
  const url = `${config.apiUrl}/status/broadcast-message/${requestId}`;

  return axios
    .put(url, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

async function automateEinreichenRequests(aliasName, autoForward) {
  const url = `${config.apiUrl}/tcs/${aliasName}/auto-forward?autoForward=${autoForward}`;
  try {
    const resp = await axios.put(url);
    return resp;
  } catch (error) {
    return error;
  }
}

async function getAutoForwardUserDetails(userEmailID) {
  const url = `${config.apiUrl}/users/email/${userEmailID}`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
}

async function getRegistrationTypesList(aliasName) {
  const url = `${config.apiUrl}/tcs/${aliasName}/registration-types`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
}

function updateRegistrationTypesList(aliasName, registrationTypes) {
  const url = `${config.apiUrl}/tcs/${aliasName}/registration-types`;

  return axios
    .put(url, registrationTypes)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

async function getAPIUserIdDetails() {
  const url = `${config.apiUrl}/groups/access-roles/api-group`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
}

async function downloadGKSCertificate(tenantAliasName, fileType) {
  const url = `${config.apiUrl}/tcs/${tenantAliasName}/gks-certificate/${fileType}`;
  try {
    const resp = await axios.get(url, { responseType: "blob" });
    return resp;
  } catch (error) {
    return error;
  }
}

export const verimiAdminService = {
  getDetail,
  updateDetails,
  getOperationIDs,
  addOperationID,
  updateOperationID,
  deleteOperationID,
  getEmployeeIDs,
  linkOperationIdsWithEmployeeId,
  getOperationIDsLinkedWithEmployeeID,
  getEmployeeIDsLinkedWithOperationID,
  deleteLinkedIDs,
  addLinkedIDs,
  deleteTenantGKSCertificate,
  addOperationIdLinkedWithEmployeeIds,
  getUserDetailsData,
  getWebhookDetailsData,
  deleteWebhookDetails,
  submitWebhookRequest,
  testWebhookRequest,
  updateWebhookRequest,
  getUserGroupsData,
  handleAdminActionPopupSubmit,
  updateUserDetails,
  createUser,
  getNachrichtenData,
  createNachrichten,
  editNachrichten,
  handleNachrichtenActionPopupSubmit,
  automateEinreichenRequests,
  getAutoForwardUserDetails,
  getRegistrationTypesList,
  updateRegistrationTypesList,
  getAPIUserIdDetails,
  downloadGKSCertificate,
};
