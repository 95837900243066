import { toast } from "react-toastify";

// Service
import { vorgangeListService } from "../../services/vorgange/vorgangeListService";

// Notification Message
import NotificationMessage from "../../../components/core/NotificationMessage";

// Constants
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";
import {
  RESET_SUPPORT_KBA_SUCCESS,
  SUPPORT_KBA_REQUEST,
  SUPPORT_KBA_SUCCESS,
} from "../../../constants/RequestXMLLogsConstants";

function vorgangeListSuccess(resp) {
  return { type: "GET_VORGANGE_LIST", resp };
}

function getVorgangeList(query, activeTab) {
  return (dispatch) => {
    return vorgangeListService
      .getVorgangeList(query, activeTab)
      .then((resp) => {
        dispatch(vorgangeListSuccess(resp));
        return resp;
      });
  };
}

function pullRequestDetailsRequest() {
  return { type: "GETTING_ROW_DETAILS_RESPONSE" };
}

function pullRequestDetailsSuccess(resp) {
  return { type: "ROW_DETAILS_RESPONSE_SUCCESS", resp };
}

function getPullRequestDetails(id) {
  return (dispatch) => {
    dispatch(pullRequestDetailsRequest());
    return vorgangeListService.getPullRequestDetails(id).then((resp) => {
      dispatch(pullRequestDetailsSuccess(resp));
    });
  };
}

function exportDetailsInXmlRequest() {
  return { type: "GETTING_DETAILS_XML" };
}

function exportDetailsInXmlSuccess(resp) {
  return { type: "GETTING_DETAILS_XML_SUCCESS", resp };
}

function exportDetailsInXml(id) {
  return (dispatch) => {
    dispatch(exportDetailsInXmlRequest());
    return vorgangeListService.exportDetailsInXml(id).then((resp) => {
      dispatch(exportDetailsInXmlSuccess(resp));
    });
  };
}

function readRecord(ids) {
  return () => {
    return vorgangeListService.readRecord(ids).then((resp) => {
      return resp;
    });
  };
}

function setDashboardTableQuery(query) {
  return (dispatch) => {
    dispatch({ type: "SET_DASHBOARD_TABLE_QUERY", query });
  };
}

const isDashboardTableEinreichenNotification = (
  booleanValue,
  notificationData
) => {
  return (dispatch) => {
    dispatch({
      type: "IS_DASHBOARD_EINREICHEN_NOTIFICATION",
      data: { isEinreichenNotification: booleanValue, notificationData },
    });
  };
};

function requestXmlLog(requestId) {
  return () => {
    return vorgangeListService.requestXmlLog(requestId).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        toast.success(<NotificationMessage message={resp?.data?.message} />);
        return resp;
      }
    });
  };
}
function checkRegistrationStatus(requestId) {
  return () => {
    return vorgangeListService
      .checkRegistrationStatus(requestId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          return resp;
        } else {
          toast.error(<NotificationMessage message={resp?.data?.message} />);
        }
      });
  };
}

function supportKBARequest() {
  return { type: SUPPORT_KBA_REQUEST };
}

function supportKBASuccess(resp) {
  return { type: SUPPORT_KBA_SUCCESS, resp };
}

function resetSupportKBASuccess() {
  return { type: RESET_SUPPORT_KBA_SUCCESS };
}

function getSupportKBA(kbaSupportId) {
  return (dispatch) => {
    dispatch(supportKBARequest());

    return vorgangeListService.getSupportKBA(kbaSupportId).then((resp) => {
      dispatch(supportKBASuccess(resp));
    });
  };
}

export const vorgangeListActions = {
  // Added for unit testcases starts
  vorgangeListSuccess,
  pullRequestDetailsRequest,
  pullRequestDetailsSuccess,
  exportDetailsInXmlRequest,
  exportDetailsInXmlSuccess,
  supportKBARequest,
  supportKBASuccess,
  resetSupportKBASuccess,
  // Added for unit testcases Ends

  getVorgangeList,
  getPullRequestDetails,
  exportDetailsInXml,
  readRecord,
  setDashboardTableQuery,
  isDashboardTableEinreichenNotification,
  requestXmlLog,
  getSupportKBA,
  checkRegistrationStatus,
};
