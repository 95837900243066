export const userStatusFilterOption = {
  Aktiv: "Aktiv",
  Angelegt: "Angelegt",
  Gesperrt: "Gesperrt",
};

export const messageStatusFilterOption = {
  "Nachricht aktiv": "Nachricht aktiv",
  "Nachricht datiert": "Nachricht datiert",
  "Nachricht deaktiviert": "Nachricht deaktiviert",
};

export const NACHRICHT_DEAKTIVIERT = "Nachricht deaktiviert";

export const userRoleFilterOption = {
  MDC_ADMIN: "MDC_ADMIN",
  ADMIN: "ADMIN",
  API_USER: "API_USER",
  SUPPORT: "SUPPORT",
  APPROVER: "APPROVER",
};

export const nachrichtentypeFilterValues = {
  Information: "Information",
  Warnung: "Warnung",
  Dringend: "Dringend",
};

export const nachrichtenTypeOptions = [
  { label: "Information", value: "Information" },
  { label: "Warnung", value: "Warnung" },
  { label: "Dringend", value: "Dringend" },
];

export const DRINGEND = "Dringend";
export const WARNUNG = "Warnung";
export const INFORMATION = "Information";
