import React, { Component } from "react";

// Components
import ErrorText from "../shared/ErrorText";

// Helper
import { formConstants } from "../../helpers/vorgange";
import {
  verwendungFahrzeugOptions,
  deRegistrationTypeOptions,
  // inspectionTypeOptions,
} from "../../constants/ManualRequestFormConstants";

// Core Components
import VollmachtsRelevantIcon from "../core/VollmachtsRelevantIcon";
import VollmachtLoschen from "./inner_components/VollmachtLoschen";
import { DuplicateTextField } from "./DuplicateControls";
import MDCText from "../core/MDCText";
import MDCLabel from "../core/MDCLabel";
import MDCSelect from "../core/MDCSelect";
import MDCDatePicker from "../core/MDCDatePicker";
import MDCAutoCompleteDropdown from "../core/MDCAutoCompleteDropdown";
// import MDCMonthYearPicker from "../core/MDCMonthYearPicker";

// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";

// Utils
import {
  dbFormatDate,
  // getDBMonthYearFormat
} from "../../helpers/utils";

const styles = () => ({
  formBackGround: {
    padding: "0 3%",
    paddingTop: "4%",
    marginTop: "2% !important",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.MDCColors.color18}`,
    borderRadius: "5px",
    opacity: 1,
    color: theme.palette.MDCColors.textColor,
    fontSize: "1rem",
    fontFamily: theme.palette.typography.fontFamily,
    lineHeight: 1,
  },
  detailViewBackground: {
    boxShadow: "none",
    paddingTop: "initial",
  },
  vollmachtCompleted: {
    paddingTop: "1%",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
  fieldGroup: {
    marginBottom: "1.25rem !important",
  },
  fieldGroupMargin: {
    "& .MuiFormControl-marginNormal": {
      marginBottom: "0rem !important",
      marginTop: "0.65rem !important",
    },
  },
  linkLabel: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
  },
});
class FahrzeugRequestForm extends Component {
  findKey = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };
  getAutoCompleteDropDownValue = (value, optionsArray) => {
    if (value && optionsArray?.length) {
      let dropdownValue = optionsArray?.filter((item) => item.value === value); //To get the exact object value for AutoCompleteDropdown
      return dropdownValue[0];
    }
  };
  render() {
    const vorgangsart = this.props?.vorgangsart?.value;
    const isNZRequest = vorgangsart === "NZ";
    const isTZRequest = vorgangsart === "TZ";
    const isSORequest = vorgangsart === "SO";
    const isReRegistrationRequest = ["UM", "HA", "WZ"].includes(vorgangsart); // For ReRegistration either complex(UM) or simple(WZ)
    const isUMRequest = vorgangsart === "UM"; // For complex ReRegistration only(UM)
    const isHARequest = vorgangsart === "HA";
    const isABRequest = vorgangsart === "AB"; // Deregistration

    const { classes } = this.props;
    const carDeregistrationType =
      this.props?.formValues?.merkmalverwertungsnachweis.value;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes.formBackGround} ${
            this.props.isDetailDialog ? classes.detailViewBackground : ""
          } ${
            this.props?.isVollmachtCompleted ? classes.vollmachtCompleted : ""
          }`}
        >
          {!this.props?.isDetailDialog && this.props?.isVollmachtCompleted && (
            <VollmachtLoschen
              handleModal={this.props.handleVollmachtLoschenChange}
              createNewRequest={this.props?.createNewRequest}
            />
          )}

          <div>
            {!this.props.isDetailDialog && (
              <>{this.props.selectDuplicateValues()}</>
            )}

            <Grid container spacing={3} marginBottom="2%">
              <Grid item xs={4}>
                <div>
                  <label className={classes.columnTitle}>Fahrzeugdetails</label>
                  <Grid container>
                    <Grid item xs={12} className={classes.fieldGroup}>
                      <MDCLabel label="Fahrzeugidentifizierungsnummer (FIN)" />
                      {this.props.isQESIconDisplay && (
                        <VollmachtsRelevantIcon
                          isQESCompleted={this.props.isVollmachtCompleted}
                        />
                      )}

                      <MDCText
                        name="Fahrzeugidentifizierungsnummer"
                        value={
                          this.props.formValues.Fahrzeugidentifizierungsnummer
                            .value
                        }
                        onChange={(e) => this.props.handleChange(e, 1)}
                        readOnly={
                          (!this.props.formValues.Fahrzeugidentifizierungsnummer
                            .error &&
                            this.props.isVollmachtCompleted) ||
                          this.props.readOnly
                        }
                        error={
                          this.props.formValues.Fahrzeugidentifizierungsnummer
                            .error.length > 0 && !this.props.readOnly
                        }
                        maxLength={25}
                      />
                      <ErrorText
                        id="fahrzeugidentifizierungsnummer-error"
                        error={
                          this.props.formValues.Fahrzeugidentifizierungsnummer
                            .error
                        }
                      />
                    </Grid>
                    {(isNZRequest ||
                      isTZRequest ||
                      isSORequest ||
                      isReRegistrationRequest ||
                      isABRequest) && (
                      <>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="FIN Prüfziffer" />
                          <MDCText
                            name="PruefzifferFahrzeugidentifizierungsnummer"
                            value={
                              this.props.formValues
                                .PruefzifferFahrzeugidentifizierungsnummer.value
                            }
                            onChange={(e) => this.props.handleChange(e, 1)}
                            readOnly //Make it always true as per the Stroy RQM-1258 requirement.
                            error={
                              this.props.formValues
                                .PruefzifferFahrzeugidentifizierungsnummer.error
                                .length > 0 && !this.props.readOnly
                            }
                            maxLength={1}
                          />
                          {this.props.formValues.PruefzifferFahrzeugidentifizierungsnummer.hasOwnProperty(
                            "duplicate"
                          ) && (
                            <>
                              <DuplicateTextField
                                name="PruefzifferFahrzeugidentifizierungsnummer"
                                value={
                                  this.props.formValues
                                    .PruefzifferFahrzeugidentifizierungsnummer
                                    .duplicate
                                }
                                readOnly
                                step={this.props.step}
                                onAccept={() =>
                                  this.props.resolveConflict(
                                    "PruefzifferFahrzeugidentifizierungsnummer",
                                    true
                                  )
                                }
                                onReject={() =>
                                  this.props.resolveConflict(
                                    "PruefzifferFahrzeugidentifizierungsnummer",
                                    false
                                  )
                                }
                                maxLength={1}
                              />
                            </>
                          )}
                          {!this.props.readOnly && (
                            <ErrorText
                              id="pruefzifferFahrzeugidentifizierungsnummer-error"
                              error={
                                this.props.formValues
                                  .PruefzifferFahrzeugidentifizierungsnummer
                                  .error
                              }
                            />
                          )}
                        </Grid>
                        {(isNZRequest || isTZRequest || isSORequest) && (
                          <>
                            <Grid item xs={12} className={classes.fieldGroup}>
                              <MDCLabel
                                label="Herstellerschlüsselnummer (HSN)"
                                isOptional
                              />
                              <MDCText
                                name="SchluesselHersteller"
                                value={
                                  this.props.formValues.SchluesselHersteller
                                    .value
                                }
                                onChange={(e) => this.props.handleChange(e, 1)}
                                readOnly={
                                  (!this.props.formValues.SchluesselHersteller
                                    .error &&
                                    this.props.isVollmachtCompleted) ||
                                  this.props.readOnly
                                }
                                error={
                                  this.props.formValues.SchluesselHersteller
                                    .error.length > 0 && !this.props.readOnly
                                }
                                maxLength={4}
                              />
                              {this.props.formValues.SchluesselHersteller.hasOwnProperty(
                                "duplicate"
                              ) && (
                                <>
                                  <DuplicateTextField
                                    name="SchluesselHersteller"
                                    value={
                                      this.props.formValues.SchluesselHersteller
                                        .duplicate
                                    }
                                    readOnly
                                    step={this.props.step}
                                    onAccept={() =>
                                      this.props.resolveConflict(
                                        "SchluesselHersteller",
                                        true
                                      )
                                    }
                                    onReject={() =>
                                      this.props.resolveConflict(
                                        "SchluesselHersteller",
                                        false
                                      )
                                    }
                                    maxLength={4}
                                  />
                                </>
                              )}
                              {!this.props.readOnly && (
                                <ErrorText
                                  id="schluesselHersteller-error"
                                  error={
                                    this.props.formValues.SchluesselHersteller
                                      .error
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} className={classes.fieldGroup}>
                              <MDCLabel
                                label="Typschlüsselnummer (TSN)"
                                isOptional
                              />
                              <MDCText
                                name="SchluesselTyp"
                                value={
                                  this.props.formValues.SchluesselTyp.value
                                }
                                onChange={(e) => this.props.handleChange(e, 1)}
                                readOnly={
                                  (!this.props.formValues.SchluesselTyp.error &&
                                    this.props.isVollmachtCompleted) ||
                                  this.props.readOnly
                                }
                                error={
                                  this.props.formValues.SchluesselTyp.error
                                    .length > 0 && !this.props.readOnly
                                }
                                maxLength={3}
                              />
                              {this.props.formValues.SchluesselTyp.hasOwnProperty(
                                "duplicate"
                              ) && (
                                <>
                                  <DuplicateTextField
                                    name="SchluesselTyp"
                                    value={
                                      this.props.formValues.SchluesselTyp
                                        .duplicate
                                    }
                                    readOnly
                                    step={this.props.step}
                                    onAccept={() =>
                                      this.props.resolveConflict(
                                        "SchluesselTyp",
                                        true
                                      )
                                    }
                                    onReject={() =>
                                      this.props.resolveConflict(
                                        "SchluesselTyp",
                                        false
                                      )
                                    }
                                    maxLength={3}
                                  />
                                </>
                              )}
                              {!this.props.readOnly && (
                                <ErrorText
                                  id="schluesselTyp-error"
                                  error={
                                    this.props.formValues.SchluesselTyp.error
                                  }
                                />
                              )}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4}>
                {(isNZRequest ||
                  isTZRequest ||
                  isSORequest ||
                  isReRegistrationRequest) && (
                  <div>
                    <label className={classes.columnTitle}>
                      Fahrzeugversicherung
                    </label>
                    <Grid container>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="elektronische Versicherungsbestätigungsnummer (eVB)" />
                        <MDCText
                          name="Versicherungsbestaetigungsnummer"
                          value={
                            this.props.formValues
                              .Versicherungsbestaetigungsnummer.value
                          }
                          onChange={(e) => this.props.handleChange(e, 1)}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues
                              .Versicherungsbestaetigungsnummer.error.length >
                              0 && !this.props.readOnly
                          }
                          maxLength={20}
                        />
                        {this.props.formValues.Versicherungsbestaetigungsnummer.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="Versicherungsbestaetigungsnummer"
                              value={
                                this.props.formValues
                                  .Versicherungsbestaetigungsnummer.duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "Versicherungsbestaetigungsnummer",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "Versicherungsbestaetigungsnummer",
                                  false
                                )
                              }
                              maxLength={20}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="versicherungsbestaetigungsnummer-error"
                            error={
                              this.props.formValues
                                .Versicherungsbestaetigungsnummer.error
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}
                {(isNZRequest ||
                  isTZRequest ||
                  isSORequest ||
                  isReRegistrationRequest) && (
                  <div>
                    <label className={classes.columnTitle}>Verwendung</label>
                    <Grid container>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Fahrzeug" isOptional />
                        <MDCSelect
                          name="VerwendungFahrzeug"
                          value={
                            this.props?.formValues?.VerwendungFahrzeug?.value
                          }
                          handleChange={(e) => this.props.handleChange(e, 1)}
                          disabled={this.props.readOnly}
                          error={
                            this.props.formValues.VerwendungFahrzeug.error
                              .length > 0 && !this.props.readOnly
                          }
                          options={verwendungFahrzeugOptions}
                        />

                        {this.props.formValues.VerwendungFahrzeug.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="VerwendungFahrzeug"
                              value={this.findKey(
                                formConstants,
                                this.props.formValues.VerwendungFahrzeug
                                  .duplicate
                              )}
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "VerwendungFahrzeug",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "VerwendungFahrzeug",
                                  false
                                )
                              }
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="verwendungFahrzeug-error"
                            error={
                              this.props.formValues.VerwendungFahrzeug.error
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* In Furure we will use this code US: RQM-4955*/}
                {/* {isReRegistrationRequest && (
                  <div>
                    <label className={classes.columnTitle}>
                      Hauptuntersuchung (optional)
                    </label>
                    <Grid container>
                      <Grid item xs={12}>
                        <MDCLabel label="Datum der nächsten Hauptuntersuchung" />

                        <div className="mt-2">
                          <MDCMonthYearPicker
                            onChange={(date) => {
                              let event = {
                                target: {
                                  name: "NaechsteHauptuntersuchung",
                                  type: "date",
                                  value: getDBMonthYearFormat(date) || null,
                                },
                              };
                              this.props.handleChange(event, 1);
                            }}
                            value={
                              this.props.formValues.NaechsteHauptuntersuchung
                                .value
                            }
                            readOnly={this.props.readOnly}
                            error={
                              this.props.formValues.NaechsteHauptuntersuchung
                                .error.length > 0 && !this.props.readOnly
                            }
                          />
                        </div>

                        {this.props.formValues?.NaechsteHauptuntersuchung?.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="NaechsteHauptuntersuchung"
                              value={
                                this.props.formValues.NaechsteHauptuntersuchung
                                  .duplicate
                                  ? this.formatDisplayDate(
                                      this.props.formValues
                                        .NaechsteHauptuntersuchung.duplicate
                                    )
                                  : ""
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "NaechsteHauptuntersuchung",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "NaechsteHauptuntersuchung",
                                  false
                                )
                              }
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="NaechsteHauptuntersuchung-error"
                            error={
                              this.props.formValues?.NaechsteHauptuntersuchung
                                .error
                            }
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.fieldGroup}
                        marginTop="1rem"
                      >
                        <MDCLabel label="Art der Hauptuntersuchung" />
                        <MDCSelect
                          name="artDerUntersuchung"
                          value={
                            this.props?.formValues?.artDerUntersuchung?.value
                          }
                          handleChange={(e) => this.props.handleChange(e, 1)}
                          disabled={this.props.readOnly}
                          error={
                            this.props.formValues.artDerUntersuchung.error
                              .length > 0 && !this.props.readOnly
                          }
                          placeholder={"Bitte auswählen"}
                          options={inspectionTypeOptions}
                        />

                        {this.props.formValues.artDerUntersuchung.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="artDerUntersuchung"
                              value={this.findKey(
                                formConstants,
                                this.props.formValues.artDerUntersuchung
                                  .duplicate
                              )}
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "artDerUntersuchung",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "artDerUntersuchung",
                                  false
                                )
                              }
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="artDerUntersuchung-error"
                            error={
                              this.props.formValues.artDerUntersuchung.error
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )} */}
                {this.props.vorgangsart.value === "AB" && (
                  <div>
                    <label className={classes.columnTitle}>Verwertung</label>
                    <Grid
                      item
                      xs={12}
                      className={classes.fieldGroup}
                      marginTop={".9rem"}
                    >
                      <MDCSelect
                        value={
                          this.props?.formValues?.merkmalverwertungsnachweis
                            .value
                        }
                        name="merkmalverwertungsnachweis"
                        handleChange={(e) => this.props.handleChange(e, 1)}
                        disabled={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly
                        }
                        options={deRegistrationTypeOptions}
                      />
                    </Grid>

                    {carDeregistrationType === "2" &&
                      this.props.countriesList.length > 1 && (
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Exportland" />
                          <Grid>
                            <MDCAutoCompleteDropdown
                              error={
                                this.props.formValues.StaatVerwertung.error
                                  .length > 0 && !this.props.readOnly
                              }
                              options={this.props.countriesList}
                              value={this.getAutoCompleteDropDownValue(
                                this.props.formValues?.StaatVerwertung?.value,
                                this.props.countriesList
                              )}
                              disabled={this.props.isDetailDialog}
                              onChange={(val) => {
                                let event = {
                                  target: {
                                    name: "StaatVerwertung",
                                    value: val.value,
                                  },
                                };
                                this.props.handleChange(event, 1);
                              }}
                            />
                          </Grid>
                          {!this.props.readOnly && (
                            <ErrorText
                              id="StaatVerwertung-error"
                              error={
                                this.props.formValues.StaatVerwertung.error
                              }
                            />
                          )}
                        </Grid>
                      )}
                    {carDeregistrationType === "0" && (
                      <>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Betriebsnummer Demontagebetrieb" />
                          <MDCText
                            name="BetriebsnummerDemontagebetrieb"
                            value={
                              this.props.formValues
                                .BetriebsnummerDemontagebetrieb.value
                            }
                            onChange={(e) => this.props.handleChange(e, 1)}
                            readOnly={this.props.readOnly}
                            error={
                              this.props.formValues
                                .BetriebsnummerDemontagebetrieb.error.length >
                                0 && !this.props.readOnly
                            }
                            maxLength={20}
                          />
                          {!this.props.readOnly && (
                            <ErrorText
                              id="BetriebsnummerDemontagebetrieb-error"
                              error={
                                this.props.formValues
                                  .BetriebsnummerDemontagebetrieb.error
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Ausstellungsdatum" />
                          <Grid item className={classes.fieldGroupMargin}>
                            <MDCDatePicker
                              isManualEntry
                              value={
                                this.props.formValues.DatumAusstellung.value
                              }
                              onChange={(date) => {
                                let event = {
                                  target: {
                                    name: "DatumAusstellung",
                                    type: "date",
                                    value:
                                      date !== null ? dbFormatDate(date) : null,
                                  },
                                };
                                this.props.handleChange(event, 1);
                              }}
                              readOnly={this.props.readOnly}
                              error={
                                this.props.formValues.DatumAusstellung.error
                                  .length > 0 && !this.props.readOnly
                              }
                            />
                            {!this.props.readOnly && (
                              <ErrorText
                                id="DatumAusstellung-error"
                                error={
                                  this.props.formValues.DatumAusstellung.error
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                )}
              </Grid>
              <Grid item xs={4}>
                {(this.props.vorgangsart.value === "AB" ||
                  isReRegistrationRequest) && (
                  <div>
                    <label className={classes.columnTitle}>
                      Zulassungsbescheinigung Teil I
                    </label>
                    <Grid container>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel
                          label="Zulassungsbescheinigung Teil I (Nummer)"
                          isOptional={isABRequest ? true : false}
                        />
                        <MDCText
                          name="NummerZulBeschTeil1"
                          value={
                            this.props.formValues.NummerZulBeschTeil1.value
                          }
                          onChange={(e) => this.props.handleChange(e, 1)}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.NummerZulBeschTeil1.error
                              .length > 0 && !this.props.readOnly
                          }
                          maxLength={20}
                        />
                        {this.props.formValues.NummerZulBeschTeil1.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="NummerZulBeschTeil1"
                              value={
                                this.props.formValues.NummerZulBeschTeil1
                                  .duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "NummerZulBeschTeil1",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "NummerZulBeschTeil1",
                                  false
                                )
                              }
                              maxLength={20}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="NummerZulBeschTeil1-error"
                            error={
                              this.props.formValues.NummerZulBeschTeil1.error
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Zulassungsbescheinigung Teil I (Sicherheitscode)" />
                        <MDCText
                          name="sicherheitscode_teil1"
                          value={
                            this.props.formValues.sicherheitscode_teil1.value
                          }
                          onChange={(e) => this.props.handleChange(e, 1)}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.sicherheitscode_teil1.error
                              .length > 0 && !this.props.readOnly
                          }
                          maxLength={7}
                        />
                        {this.props.formValues.sicherheitscode_teil1.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="sicherheitscode_teil1"
                              value={
                                this.props.formValues.sicherheitscode_teil1
                                  .duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "sicherheitscode_teil1",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "sicherheitscode_teil1",
                                  false
                                )
                              }
                              maxLength={7}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="sicherheitscode-teil1-error"
                            error={
                              this.props.formValues.sicherheitscode_teil1.error
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}

                {(isNZRequest ||
                  isTZRequest ||
                  isSORequest ||
                  isUMRequest ||
                  isHARequest ||
                  !["", "1"].includes(carDeregistrationType)) && (
                  <div>
                    <label className={classes.columnTitle}>
                      Zulassungsbescheinigung Teil II
                    </label>
                    <Grid container>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Zulassungsbescheinigung Teil II (Nummer)" />
                        <MDCText
                          name="NummerZulBeschTeil2"
                          value={
                            this.props.formValues.NummerZulBeschTeil2.value
                          }
                          onChange={(e) => this.props.handleChange(e, 1)}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.NummerZulBeschTeil2.error
                              .length > 0 && !this.props.readOnly
                          }
                          maxLength={20}
                        />
                        {this.props.formValues.NummerZulBeschTeil2.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="NummerZulBeschTeil2"
                              value={
                                this.props.formValues.NummerZulBeschTeil2
                                  .duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "NummerZulBeschTeil2",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "NummerZulBeschTeil2",
                                  false
                                )
                              }
                              maxLength={20}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="nummerZulBeschTeil2-error"
                            error={
                              this.props.formValues.NummerZulBeschTeil2.error
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Zulassungsbescheinigung Teil II (Sicherheitscode)" />
                        <MDCText
                          name="sicherheitscode_teil2"
                          value={
                            this.props.formValues.sicherheitscode_teil2.value
                          }
                          onChange={(e) => this.props.handleChange(e, 1)}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.sicherheitscode_teil2.error
                              .length > 0 && !this.props.readOnly
                          }
                          maxLength={12}
                        />

                        {this.props.formValues.sicherheitscode_teil2.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="sicherheitscode_teil2"
                              value={
                                this.props.formValues.sicherheitscode_teil2
                                  .duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "sicherheitscode_teil2",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "sicherheitscode_teil2",
                                  false
                                )
                              }
                              maxLength={12}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="sicherheitscode-teil2-error"
                            error={
                              this.props.formValues.sicherheitscode_teil2.error
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FahrzeugRequestForm);
