// User Roles
export const USER_ROLE_APPROVER = "APPROVER";
export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_MDC_ADMIN = "MDC_ADMIN";
export const USER_ROLE_API_USER = "API_USER";
export const USER_ROLE_SUPPORT = "SUPPORT";
export const USER_ROLE_EXPERT = "EXPERT";
export const USER_ROLE_ACCOUNTANT = "ACCOUNTANT";

export const userRoles = [
  USER_ROLE_APPROVER,
  USER_ROLE_SUPPORT,
  USER_ROLE_ACCOUNTANT,
  USER_ROLE_EXPERT,
  USER_ROLE_MDC_ADMIN,
  USER_ROLE_ADMIN,
  USER_ROLE_API_USER,
];

export const clientRoles = [USER_ROLE_APPROVER, USER_ROLE_ADMIN];
export const backStageRoles = [
  USER_ROLE_SUPPORT,
  USER_ROLE_ACCOUNTANT,
  USER_ROLE_EXPERT,
  USER_ROLE_MDC_ADMIN,
];

export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",

  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  GRANT_TYPE: "password",

  USER_UPDATE: "USER_UPDATE",

  COMPANY_DETAILS_SUCCESS: "COMPANY_DETAILS_SUCCESS",

  // Verimi Register
  VERIMI_REGISTER_SUCCESS: "VERIMI_REGISTER_SUCCESS",

  GET_PRODUCT_VERSION_SUCCESS: "GET_PRODUCT_VERSION_SUCCESS",

  GET_EXCEL_TEMPALTE_SUCCESS: "GET_EXCEL_TEMPALTE_SUCCESS",

  GET_TEMPALTE_PDF_SUCCESS: "GET_TEMPALTE_PDF_SUCCESS",

  GET_TENANT_LIST_SUCCESS: "GET_TENANT_LIST_SUCCESS",
};

export const SCOPE_DETAILS_HEADING_LABELS = [
  "Nutzerinformationen",
  "Scope Details",
];

export const SCOPE_DETAILS_PRIMARY_KEYS = {
  given_name: "Name",
  family_name: "Vorname",
  email: "E-Mail Adresse",
};

export const SCOPE_DETAILS_SECONDARY_KEYS = {
  // scopeId: "Scope ID",
  // basketId: "Basket ID",
  // procuration: "Procuration",
  // serviceProviderId: "Service Provider ID",
  role: "Role",
};

export const SCOPE_DETAILS_EMPLOYEE_ID = "Employee ID";

export const EMPLOYEE_ID_VALIDATION_ERRORS = [
  "Die Mitarbeiternummer darf nicht leer sein oder Leerzeichen beinhalten. Bitte überprüfen Sie Ihre Eingabe.",
  "Die Mitarbeiternummer darf nicht mehr als 25 Zeichen beinhalten. Bitte überprüfen Sie Ihre Eingabe.",
];

export const BLANK_CREDENTIALS_ERROR = {
  USER_ERROR: "*Bitte geben Sie Ihren Benutzernamen ein.",
  PASSWORD_ERROR: "*Bitte geben Sie Ihr Passwort ein.",
};
