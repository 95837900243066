import React from "react";
import MDCGroupNotification from "../../core/MDCGroupNotification";
import { Grid } from "@mui/material";
import {
  DRINGEND,
  INFORMATION,
  WARNUNG,
} from "../../../constants/TenantUserConstants";

const StatusServiceMessages = (props) => {
  const { statusServiceData, showMessageDetailDialog } = props;

  function setGlobalBannerMessages() {
    if (!statusServiceData?.length) return [];

    // setting banner messages on basis of priority i.e urgent>Warning>information
    const urgentMessages = statusServiceData?.filter(
      (message) => message.nachrichtenTyp === DRINGEND
    );
    const warningMessages = statusServiceData?.filter(
      (message) => message.nachrichtenTyp === WARNUNG
    );
    const informationMessages = statusServiceData?.filter(
      (message) => message.nachrichtenTyp === INFORMATION
    );
    return [...urgentMessages, ...warningMessages, ...informationMessages]; //returning on basis of priority
  }

  const bannerMessagesList = setGlobalBannerMessages();

  return (
    <>
      {bannerMessagesList?.length ? (
        <Grid maxHeight={"11.80rem"} overflow={"auto"}>
          {bannerMessagesList?.map((bannerInfo, index) => (
            <MDCGroupNotification
              key={index}
              groupBannerData={bannerInfo}
              showMessageDetailDialog={showMessageDetailDialog}
            />
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default StatusServiceMessages;
