import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { statusMessagesColorScheme } from "../../helpers/utils";
import Link from "@mui/material/Link";

const useStyles = makeStyles({
  statusMessagesCss: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1rem 0",
    border: "1px inset black",
  },
});

const MDCGroupNotification = (props) => {
  const { statusMessagesCss } = useStyles();
  const { title, nachrichtenTyp, description } = props.groupBannerData;

  return (
    <>
      <Grid
        container
        backgroundColor={statusMessagesColorScheme[nachrichtenTyp]?.color}
        color={statusMessagesColorScheme[nachrichtenTyp]?.textColor}
        className={statusMessagesCss}
      >
        {/**Left Icon*/}
        <Grid item xs={1.5}>
          {statusMessagesColorScheme[nachrichtenTyp]?.icon}
        </Grid>

        {/**Global Notification messages for Whole Group*/}
        <Grid item xs={6.5}>
          {title + " "}
          {description?.length ? (
            <Link
              component="button"
              color="inherit"
              variant="body1"
              onClick={() => props.showMessageDetailDialog(description)}
            >
              Details
            </Link>
          ) : (
            <></>
          )}
        </Grid>

        {/**Right Icon */}
        <Grid item xs={1.5}>
          {statusMessagesColorScheme[nachrichtenTyp].icon}
        </Grid>
      </Grid>
    </>
  );
};

export default MDCGroupNotification;
