import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MDCButton from "../../core/MDCButton";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

// Icons
import DownArrowIcon from "../../iconComponents/DownArrowIcon";
import CrossIcon from "../../iconComponents/CrossIcon";
import MDCRadioButtonWithIcon from "../../core/MDCRadioButtonWithIcon";

// Assets
import HomeBaseIcon from "../../../assets/images/Icon_homeBase.svg";

// Core Components
import MDCDrawer from "../../core/MDCDrawer";
import NotificationMessage from "../../../components/core/NotificationMessage";

// Helper
import { history } from "../../../helpers/history";

// Constants
import {
  ORGANIZATION_SWITCHED,
  ORGANIZATION_SWITCHED_ERROR,
} from "../../../constants/messageConstants";

// Actions
import { accountManagementActions } from "../../../store/actions/userAccount/accountManagementActions";

const useStyles = makeStyles((theme) => ({
  drawerBodystyle: {
    paddingTop: "2rem",
    paddingLeft: "1rem",
    paddingRight: "2rem",
    width: "30vw",
    cursor: "pointer",
  },
  drawerLabelStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginBottom: "2rem",
    color: theme.palette.MDCColors.black,
  },
}));

const MultipleLoginDrawer = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const activeUser = JSON.parse(localStorage.getItem("activeUser"));
  const sessionActiveUser = JSON.parse(sessionStorage.getItem("activeUser"));

  const getInitialActiveUser = () => {
    if (sessionActiveUser) {
      return sessionActiveUser?.value;
    } else if (activeUser) {
      return activeUser?.value;
    } else {
      return user?.pTenantAlias;
    }
  };
  const { drawerBodystyle, drawerLabelStyle } = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loginType, setLoginType] = useState(getInitialActiveUser());
  const [loginOptions, setLoginOptions] = useState([]);
  const dispatch = useDispatch();

  const tenantList = useSelector(
    (state) => state?.accountManagementReducer?.tenantList
  );

  useEffect(() => {
    !tenantList?.length &&
      dispatch(accountManagementActions.getAvailableTenantList(user?.sub));
  }, []);

  useEffect(() => {
    setLoginOptions(tenantList);
  }, [tenantList]);

  const getButtonDetails = (detailType) => {
    const loginOption = loginOptions?.find(
      (option) => loginType === option?.value
    );

    if (detailType === "label") return loginOption?.label || "";
    if (detailType === "startIcon") {
      return loginOption?.isIconPresent ? (
        <img src={HomeBaseIcon} height={"20px"} />
      ) : null;
    }
  };

  const drawerBody = (
    <Grid className={drawerBodystyle}>
      <Grid className={drawerLabelStyle}>
        Verfügbare Organisationen
        <Grid onClick={() => setOpenDrawer(false)}>
          <CrossIcon />
        </Grid>
      </Grid>

      <MDCRadioButtonWithIcon
        name="homebaseLogin"
        selected={loginType}
        onChange={(e) => {
          sessionStorage.clear();
          const value = e.target.value;

          if (value) {
            dispatch(
              accountManagementActions.getAvailableTenantList(user?.sub)
            ).then((resp) => {
              if (resp) {
                const newloginOptionsResponse = resp;

                setLoginOptions(newloginOptionsResponse);

                const loggedInUser = newloginOptionsResponse?.find(
                  (loginOptionDetails) => loginOptionDetails?.value === value
                );

                if (!loggedInUser) {
                  return toast.error(
                    <NotificationMessage
                      message={ORGANIZATION_SWITCHED_ERROR}
                    />
                  );
                }

                setLoginType(value);

                () => setOpenDrawer(false);
                localStorage.setItem(
                  "activeUser",
                  JSON.stringify(loggedInUser)
                );
                sessionStorage.setItem("isTenantSwitched", true);
                toast.success(
                  <NotificationMessage message={ORGANIZATION_SWITCHED} />
                );
                history.push("/dashboard");
              }
            });
          }
        }}
        options={loginOptions}
        iconComponent={<img src={HomeBaseIcon} />}
      />
    </Grid>
  );

  return tenantList?.length > 1 ? (
    <div>
      <MDCButton
        label={getButtonDetails("label")}
        variant="outlined"
        onClick={() => setOpenDrawer(true)}
        endIcon={<DownArrowIcon />}
        startIcon={getButtonDetails("startIcon")}
      />

      <MDCDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
      >
        {drawerBody}
      </MDCDrawer>
    </div>
  ) : (
    ""
  );
};

export default MultipleLoginDrawer;
